import Vue from 'vue'
import Vuex from 'vuex'
import article from './modules/article'
import product from './modules/product'

Vue.use(Vuex)

export default new Vuex.Store({
  modules:{
    article,
    product
  }
})
