<template>
  <div>
    <div class="box2">
      <div class="w">
        <el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal" @select="handleSelect"
          text-color="#3c3c3c" active-text-color="#82ded0">
          <el-submenu index="1">
            <template slot="title">电子通行证</template>
            <el-menu-item index="1-1">通行证类型</el-menu-item>
            <el-menu-item index="1-2">通行证分类</el-menu-item>
            <el-menu-item index="1-3">二维码生成</el-menu-item>
            <el-menu-item index="1-4">通行证审核</el-menu-item>
            <el-menu-item index="1-5">通行证管理</el-menu-item>
            <el-menu-item index="1-6">出入记录</el-menu-item>
          </el-submenu>
          <el-submenu index="2">
            <template slot="title">服务预约</template>
            <el-menu-item index="2-1">办事指南</el-menu-item>
            <el-menu-item index="2-2">服务预约审核</el-menu-item>
            <el-menu-item index="2-3">服务预约事项</el-menu-item>
            <el-menu-item index="2-4">指南服务类型</el-menu-item>
          </el-submenu>
          <el-submenu index="3">
            <template slot="title">志愿者</template>
            <el-menu-item index="3-1">志愿者查看与审核</el-menu-item>
          </el-submenu>
          <el-submenu index="4">
            <template slot="title">活动报名</template>
            <el-menu-item index="4-1">报名审核</el-menu-item>
            <el-menu-item index="4-2">活动发布</el-menu-item>
            <el-menu-item index="4-3">报名表自定义</el-menu-item>
            <el-menu-item index="4-4">活动类型</el-menu-item>
            <el-menu-item index="4-5">在线活动设置</el-menu-item>
          </el-submenu>
          <el-submenu index="5">
            <template slot="title">一刻钟服务圈</template>
            <el-menu-item index="5-1">商户审核</el-menu-item>
            <el-menu-item index="5-2">商户认领审核</el-menu-item>
            <el-menu-item index="5-3">商户管理</el-menu-item>
            <el-menu-item index="5-4">商户类型</el-menu-item>
          </el-submenu>
          <el-submenu index="6">
            <template slot="title">议事平台</template>
            <el-menu-item index="6-1">议题征集</el-menu-item>
            <el-menu-item index="6-2">议题评选</el-menu-item>
            <el-menu-item index="6-3">议事会议</el-menu-item>
            <el-menu-item index="6-4">议题实施</el-menu-item>
            <el-menu-item index="6-5">议题监督提议</el-menu-item>
            <el-menu-item index="6-6">议题类型设置</el-menu-item>
            <el-menu-item index="6-7">会议类型设置</el-menu-item>
            <el-menu-item index="6-8">线上会议</el-menu-item>
          </el-submenu>
          <el-menu-item index="7">调查问卷</el-menu-item>
          <el-submenu index="8">
            <template slot="title">社区党建</template>
            <el-menu-item index="8-1">党组织管理</el-menu-item>
            <el-menu-item index="8-2">党员管理</el-menu-item>
          </el-submenu>
        </el-menu>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      activeIndex: '1',
    }
  },
  methods: {
    handleSelect(key, keyPath) {
      console.log(key, keyPath);
    }
  },
}
</script>