import {getColumnList,getArticleList,getArticle,getArticleListIndex} from '@/api/article'

    const state={
        title:"",
        columnList:[],
        articleList:[],
        article:{},
        articleIndex:[]
    }

    const mutations = {
        SET_COLUMNLIST: (state, list) => {
          if (list !== null && list.length>0 ) {
            state.columnList=list;
            state.title=list[0].columnName;
          }
        },
        SET_ARTICLELIST: (state, list) => {
          if (list !== null ) {
            state.articleList=list;
          }
        },
        SET_ARTICLELISTINDEX: (state, list) => {
          if (list !== null ) {
            state.articleIndex=list;
          }
        },
        SET_TITLE: (state, title) => {
          if (title !== null ) {
            state.title=title;
          }
        },
        SET_ARTICLE: (state, article) => {
            state.article=article;
            console.log(article)
        }
    }

    const actions={
        getColumnList:({commit,dispatch},payLoad)=>{
            getColumnList().then(res=>{
                commit("SET_COLUMNLIST",res.data);
                dispatch("getArticleList",{columnId:res.data[0].columnId});
            })
        },
        getArticleList:({commit},payLoad)=>{
            getArticleList(payLoad).then(res=>{
                commit("SET_ARTICLELIST",res.rows);
            })
        },
        getArticleListIndex:({commit},payLoad)=>{
          getArticleListIndex(payLoad).then(res=>{
                commit("SET_ARTICLELISTINDEX",res.rows);
            })
        },
        setTitle:({commit},title)=>{
                commit("SET_TITLE",title);
        },
        getArticle:({commit},articleId)=>{
            getArticle(articleId).then(res=>{
                commit("SET_ARTICLE",res.data);
            })
        },
        setArticle:({commit},article)=>{
            commit("SET_ARTICLE",article);
        }
    }
    
 export default {
    state,
    mutations,
    actions,
    namespaced:true
 };