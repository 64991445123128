<template>
  <div>
    <div class="footer">
      <div class="w">
        <div class="footer-nav">
          <span>
            <h2>开启智慧平台</h2>
            <el-row>
              <el-button type="primary"  @click="gotoControl('test')">免费体验</el-button>
            </el-row>
          </span>
          <ul>
            <li>地址</li>
            <li>北京市东城区快易名商办公楼B023</li>
          </ul>
          <ul>
            <li>联系方式</li>
            <li class="el-icon-message">1078737959@QQ&nbsp;.&nbsp;COM</li>
          </ul>
          <img src="../assets/images/weChat.png" alt="">
        </div>
        <hr>
        <div class="banquan">
          <a href="https://beian.miit.gov.cn/#/Integrated/index">京ICP备2023005400号-1</a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
    }
  },
  methods: {
    gotoControl(t){
      localStorage.setItem("buy",t);
      location.href="/admin/article/order"
    }
  }
}
</script>
<style></style>