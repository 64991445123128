<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
export default {
  created(){
    window.IT={
      getCookie:function (cname) {
        var name = cname + "=";
        var ca = document.cookie.split(';');
        for (var i = 0; i < ca.length; i++) {
          var c = ca[i];
          while (c.charAt(0) == ' ') c = c.substring(1);
          if (c.indexOf(name) != -1){
            return c.substring(name.length, c.length);
          }
        }
        return "";
      }
    }
  }
}
</script>
<style lang="scss">
#app {}
</style>
