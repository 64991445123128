<template>
  <div>
    <!-- 头部组件 -->
    <IndexHead></IndexHead>
    <!-- 列表内容部分 -->
    <div class="w">
      <el-container>
        <el-header>{{ getTitle }}</el-header>
        <el-container v-loading="this.loading.getColumnList">
          <el-aside width="200px">
            <el-row>
              <el-col :span="24">
                <el-menu default-active="1" v-for="(item,key) in this.$store.state.article.columnList" class="el-menu-vertical-demo" @open="handleOpen" @close="handleClose"
                  background-color="#545c64" text-color="#fff" active-text-color="#ffd04b">
                  <el-menu-item :index="0+item.columnId.toString">
                    <span slot="title" @click="huoqu(item.columnId,key)">
                      <i class="el-icon-star-on"></i>
                      {{ item.columnName}}</span>
                  </el-menu-item>
                </el-menu>
              </el-col>
            </el-row>
          </el-aside>
          <el-main>
            <div class="dongtai" >
              <div v-show="this.loading.getArticleList" class="loading-div" v-loading="this.loading.getArticleList"></div>
              <el-empty v-show="this.$store.state.article.articleList.length==0" description="暂无资讯"></el-empty>
              <div  v-for="(item) in this.$store.state.article.articleList">
              <div class="liebiao" >
                <router-link :to="'/ListDetailed/'+item.artiicleId">{{ item.title }}</router-link>
                <i>{{ item.createTime.slice(0,10) }}</i>
              </div>
              <el-divider></el-divider>
            </div>
          </div>
          </el-main>
        </el-container>
      </el-container>
      <!-- 分页器 -->
      <el-pagination background layout="prev, pager, next" :total="1000" class="pagination">
      </el-pagination>
    </div>
    <!-- 尾部组件 -->
    <IndexFooter></IndexFooter>
  </div>
</template>
<script>
import IndexHead from '@/components/IndexHead.vue'
import IndexFooter from '@/components/IndexFooter.vue'
export default {
  name: 'list',
  data() {
    return {
      title:"",
      loading:{
        getColumnList:true,
        getArticleList:true
      }
    }
  },
  computed:{
      getTitle(){
        return this.$store.state.article.title;
      }
  },
  methods: {
    handleOpen(key, keyPath) {
      console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath);
    },
    // change: function (index) {
    //   this.number = index;
    // },
    huoqu(columnId,key) {
      this.loading.getArticleList=true;
      this.$store.dispatch("article/getArticleList",{columnId}).then(()=>{
      this.loading.getArticleList=false;
      this.$store.dispatch("article/setTitle",this.$store.state.article.columnList[key].columnName)
      })
    }
  },
  components: {
    IndexHead,
    IndexFooter,
  },
  created(){
    this.$store.dispatch("article/getColumnList",{}).then((e)=>{
      this.loading.getColumnList=false
      this.loading.getArticleList=false
    });
    
  }
}
</script>
<style lang="scss">
@import '@/styles/list.scss';
.loading-div {
  margin-top: 30px;
}
</style>
