import Vue from 'vue';
import VueRouter from 'vue-router';
import index from '../views/index/index.vue';
import list from '../views/list/list.vue';
import ListDetailed from '../views/ListDetailed/ListDetailed.vue';
// import HeadDialog from '../components/HeadDialog.vue';
Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'index',
    component: index,
  },
  {
    path: '/list',
    name: 'list',
    component: list,
    // children: [{ path: 'ListDetailed', component: ListDetailed }],
  },
  {
    path: '/ListDetailed/:articleCode',
    name: 'ListDetailed',
    component: ListDetailed,
  },
  // {
  //   path: '/HeadDialog',
  //   name: 'HeadDialog',
  //   component: HeadDialog,
  // },
];

const router = new VueRouter({
  routes,
});

export default router;
