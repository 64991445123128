<template>
  <!-- 最大的容器 -->
  <div>
    <IndexHead></IndexHead>
    <!-- 内容部分1 -->
    <div class="box3">
      <div class="container">
        <div class="caption-content text-center">
          <h4>开始智慧互联</h4>
          <h2>互联社区
            <br>
            智慧大脑
          </h2>
          <p>“十四五”规划和2035年远景目标纲要要求构建基层社会治理新格局，推动社会治理和服务重心下移、资源下沉，
            <br>
            提高城乡社区精准化精细化服务管理能力，建设人人有责、人人尽责、人人享有的社会治理共同体。
          </p>
        </div>
      </div>
    </div>
    <!-- 内容部分1.2 -->
    <div class="box7">
      <div class="w">
        <h2>互联社区系统功能</h2>
        <div class="Product">
          <el-card :body-style="{ padding: '0px' }">
            <div class="image">
              <img src="@/assets/images/chanpin1.jpg" alt="">
            </div>
            <h4>电子通行证</h4>
            <p style="text-align: left;">电子通行证不仅可以免去人工登录的繁琐，而且大大提升了工作效率，通过系统后台可实时掌握需要的各类数据。</p>
          </el-card>
          <el-card :body-style="{ padding: '0px' }">
            <div class="image">
              <img src="@/assets/images/chanpin2.jpg" alt="">
            </div>
            <h4>服务预约</h4>
            <p style="text-align: left;">服务预约可以节省宝贵的时间，根据办事人自身时间有计划的安排预约时间，避免长时间等候。</p>
          </el-card>
          <el-card :body-style="{ padding: '0px' }">
            <div class='image'>
              <img src="@/assets/images/chanpin3.jpg" alt="">
            </div>

            <h4>志愿者</h4>
            <p style="text-align: left;">简化社区志愿者的申请和审核流程。</p>
          </el-card>
          <el-card :body-style="{ padding: '0px' }">
            <div class="image">
              <img src="@/assets/images/chanpin4.jpg" alt="">
            </div>
            <h4>活动报名</h4>
            <p style="text-align: left;">管理员发起活动报名功能，支持添加活动主题、分类、类型、时间、地点、费用等各类功能。可在后台查看报名记录，支持导出报名数据。</p>
          </el-card>
          <el-card :body-style="{ padding: '0px' }" style="height: 300px;">
            <div class="image">
              <img src="@/assets/images/chanpin5.jpg" alt="">
            </div>
            <h4>一刻钟服务圈</h4>
            <p style="text-align: left;">社区居民居住地附近范围能够享受到方便、快捷、舒适的社区服务，包括社会力量和居民个人提供的志愿互助服务，市场机制提供的便民利民服务以及特色服务等。</p>
          </el-card>
          <el-card :body-style="{ padding: '0px' }" style="height: 300px;">
            <div class="image">
              <img src="@/assets/images/chanpin6.jpg" alt="">
            </div>
            <h4>议事平台</h4>
            <p style="text-align: left;">可定期组织开展协商议事，随时随地让居民参与议事，让居民在协商议事中“做主角”。</p>
          </el-card>
          <el-card :body-style="{ padding: '0px' }" style="height: 300px;">
            <div class="image">
              <img src="@/assets/images/wenjuan.jpeg" alt="">
            </div>
            <h4>调查问卷</h4>
            <p style="text-align: left;">方便的生成相关调查问卷，并及时的了解相应信息，同时还可以定制统计的条件以及设定答卷的方式。支持导出调查问卷结果。</p>
          </el-card>
          <el-card :body-style="{ padding: '0px' }" style="height: 300px;">
            <div class="image">
              <img src="@/assets/images/chanpin8.jpg" alt="">
            </div>
            <h4>社区党建</h4>
            <p style="text-align: left;">
              如果说社区治理工作面临的是上上下下“千条线”，那么毋庸置疑，社区党建是穿起“千条线”的“一根针”。良好的社区治理秩序和成效，始终离不开社区党建工作的有力引领。</p>
          </el-card>
        </div>
      </div>
    </div>
    <!-- 内容部分2 -->
    <div class="box4">
      <div class="w">
        <h2>产品价格</h2>
        <div class="jiage">
          <el-card :body-style="{ padding: '0px' }">
            <div class="jiagetupian">
              <img src="@/assets/images/Jiage1 .jpg" alt="">
            </div>
            <h4>免费试用</h4>
            <p>0元</p>
            <div class="biggongneng">
              <span class="gongneng">
                <i class="iconfont icon-duigou" style="color: #bfbfbf;"></i>
                &nbsp;
                <s>50+个功能点</s>
              </span>
              <br>
              <span class="gongneng">
                <i class="iconfont icon-duigou"></i>
                &nbsp;
                <b>快速生成通行证二维码</b>
              </span>
              <br>
              <span class="gongneng">
                <i class="iconfont icon-duigou"></i>
                &nbsp;
                <b>便捷发布办事指南</b>
              </span>
              <br>
              <span class="gongneng">
                <i class="iconfont icon-duigou"></i>
                &nbsp;
                <b>一键审核</b>
              </span>
              <br>
              <span class="gongneng">
                <i class="iconfont icon-duigou"></i>
                &nbsp;
                <b>一刻钟服务圈</b>
              </span>
              <br>
              <span class="gongneng">
                <i class="iconfont icon-duigou"> </i>
                &nbsp;
                <b>设置调查问卷</b>
              </span>
              <br>
              <span class="gongneng">
                <i class="iconfont icon-duigou" style="color: #bfbfbf;"></i>
                &nbsp;
                <s>后台数据表单</s>
              </span>
              <br>
              <span class="gongneng">
                <i class="iconfont icon-duigou" style="color: #bfbfbf;"></i>
                &nbsp;
                <s>党建服务</s>
              </span>
              <br>
              <span class="gongneng">
                <i class="iconfont icon-duigou" style="color: #bfbfbf;"></i>
                &nbsp;
                <s>专属客服通道</s>
              </span>
              <br>
              <span class="gongneng">
                <i class="iconfont icon-duigou" style="color: #bfbfbf;"></i>
                &nbsp;
                <s>1对1行业顾问</s>
              </span>
            </div>
            <el-button @click="gotoControl('test')">立即试用</el-button>
          </el-card>
          <el-card :body-style="{ padding: '0px' }">
            <div class="jiagetupian">
              <img src="@/assets/images/Jiage2.jpg" alt="">
            </div>
            <h4>正式版本</h4>
            <p>30000元</p>
            <div class="biggongneng">
              <span class="gongneng2">
                <i class="iconfont icon-duigou"></i>
                &nbsp;
                <b>50+个功能点</b>
              </span>
              <br>
              <span class="gongneng2">
                <i class="iconfont icon-duigou"></i>
                &nbsp;
                <b>快速生成通行证二维码</b>
              </span>
              <br> <span class="gongneng2">
                <i class="iconfont icon-duigou"></i>
                &nbsp;
                <b>便捷发布办事指南</b>
              </span>
              <br> <span class="gongneng2">
                <i class="iconfont icon-duigou"></i>
                &nbsp;
                <b> 一键审核</b>
              </span>
              <br> <span class="gongneng2">
                <i class="iconfont icon-duigou"></i>
                &nbsp;
                <b>一刻钟服务圈</b>
              </span>
              <br> <span class="gongneng2">
                <i class="iconfont icon-duigou"></i>
                &nbsp;
                <b>设置调查问卷</b>
              </span>
              <br> <span class="gongneng2">
                <i class="iconfont icon-duigou"></i>
                &nbsp;
                <b>后台数据表单</b>
              </span>
              <br> <span class="gongneng2">
                <i class="iconfont icon-duigou"></i>
                &nbsp;
                <b>党建服务</b>
              </span>
              <br>
              <span class="gongneng2">
                <i class="iconfont icon-duigou"></i>
                &nbsp;
                <b>专属客服通道</b>
              </span>
              <br>
              <span class="gongneng">
                <i class="iconfont icon-duigou" style="color: #bfbfbf;"></i>
                &nbsp;
                <s>1对1行业顾问</s>
              </span>
            </div>
            <el-button @click="gotoControl('pro')">立即购买</el-button>
          </el-card>
          <el-card :body-style="{ padding: '0px' }">
            <div class="jiagetupian">
              <img src="@/assets/images/Jiage3.jpg" alt="">
            </div>
            <h4>平台定制开发</h4>
            <p>联系客服</p>
            <div class="biggongneng">
              <span class="gongneng3">
                <i class="iconfont icon-duigou"></i>
                &nbsp;
                <b>50+个功能点</b>
              </span>
              <br> <span class="gongneng3">
                <i class="iconfont icon-duigou"></i>
                &nbsp;
                <b>快速生成通行证二维码</b>
              </span>
              <br> <span class="gongneng3">
                <i class="iconfont icon-duigou"></i>
                &nbsp;
                <b>便捷发布办事指南</b>
              </span>
              <br> <span class="gongneng3">
                <i class="iconfont icon-duigou"></i>
                &nbsp;
                <b>一键审核</b>
              </span>
              <br> <span class="gongneng3">
                <i class="iconfont icon-duigou"></i>
                &nbsp;
                <b>一刻钟服务圈</b>
              </span>
              <br> <span class="gongneng3">
                <i class="iconfont icon-duigou"></i>
                &nbsp;
                <b>设置调查问卷</b>
              </span>
              <br> <span class="gongneng3">
                <i class="iconfont icon-duigou"></i>
                &nbsp;
                <b>后台数据表单</b>
              </span>
              <br> <span class="gongneng3">
                <i class="iconfont icon-duigou"></i>
                &nbsp;
                <b>党建服务</b>
              </span>
              <br> <span class="gongneng3">
                <i class="iconfont icon-duigou"></i>
                &nbsp;
                <b>专属客服通道</b>
              </span>
              <br> <span class="gongneng3">
                <i class="iconfont icon-duigou"></i>
                &nbsp;
                <b>1对1行业顾问</b>
              </span>
            </div>
            <el-button  @click="gotoControl('diy')">联系客服</el-button>
          </el-card>
        </div>
      </div>
    </div>
    <!-- 关于我们 -->
    <div class="box5">
      <div class="juzhong">


        <div class="grid-content bg-purple">
          <h2>关于我们</h2>
          <p>北京互联社会组织资源中心成立于2012年3月，是北京市民政局主管市级民办非企业单位，2013年被评为4A级社会组织；2016年入围北京市“最美社工团队”提名。
          </p>
          <p>
            自成立以来，机构依靠专业知识优势和独特资源优势，推动政府力量与社会力量的互联、互补、互动和整合，推动社会组织和社区发展，促进社会管理创新。团队秉承“团结、服务、敬业、创新”理念，以专业知识武装自己，评估近千家社会服务机构，为朝阳、东城、大兴、海淀、石景山等近30街道200余社区开展社区自组织培育、居民议事厅、环境治理、社区治理信息平台建设等项目，积累了丰富经验。
          </p>
        </div>


        <div class="grid-content bg-purple-light">
          <img src="@/assets/images/shequ.jpg" alt="">
        </div>


      </div>
    </div>
    <!-- 文章列表 -->
    <div class="essay">
      <div class="w">
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <span class="biaoti">资讯推荐</span>
            <el-button style="float: right; padding: 3px 0" type="text">
              <router-link to="/list">更多资讯</router-link>
            </el-button>
          </div>
          <template v-for="(item,key) in this.$store.state.article.articleIndex">
          <div class="liebiao">
            <router-link :to="'/ListDetailed/'+item.artiicleId">{{ item.title }}</router-link>
            <i>{{ item.createTime }}</i>
          </div>
          <el-divider></el-divider>
        </template>
        </el-card>
      </div>
    </div>
    <!-- 尾部 -->
    <IndexFooter></IndexFooter>
  </div>
</template>
<script>
import IndexHead from '@/components/IndexHead.vue'
import IndexFooter from '@/components/IndexFooter.vue'
export default {
  data() {
    return {
      input: '',
      inputpass: '',
      activeIndex: '1',
      activeIndex2: '1',
      flag: false,
    }
  },
  methods: {
    handleSelect(key, keyPath) {
      console.log(key, keyPath);
    },
    gotoControl(t){
      if(t=='diy'){

        this.$alert("定制版本请联系【陈女士 18888888888】", '提示：', {
        confirmButtonText: '确定',
        callback: action => {
          // this.$message({
          //   type: 'info',
          //   message: `action: ${ action }`
          // });
        }
      });
      return false;
      }
      localStorage.setItem("buy",t);
      location.href="/admin/article/order"
    }
  },
  created() {
    this.$store.dispatch("product/getProductList")
    this.$store.dispatch("article/getArticleListIndex")
  },
  components: {
    IndexHead,
    IndexFooter,
  }
}
</script>

<style lang="scss">
// @import '../../styles/index.scss'
</style>