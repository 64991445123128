import {getProductList} from '@/api/product'

    const state={
        productList:[],
    }

    const mutations = {
        SET_PRODUCTLIST: (state, list) => {
          if (list !== null && list.length>0 ) {
            state.productList=list;
          }
        }
    }

    const actions={
        getProductList:({commit},payLoad)=>{
          getProductList().then(res=>{
                commit("SET_PRODUCTLIST",res.rows);
            })
        },
    }
    
 export default {
    state,
    mutations,
    actions,
    namespaced:true
 };