import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import ElementUI from 'element-ui';
import './styles/index.scss';
import './assets/icon/iconfont.css';
import IndexHead from '@/components/IndexHead.vue';
import IndexFooter from '@/components/IndexFooter.vue';
import IndexNav from '@/components/IndexNav.vue';

Vue.use(ElementUI);
Vue.config.productionTip = false;

Vue.component('IndexHead', IndexHead);
Vue.component('IndexFooter', IndexFooter);
Vue.component('IndexNav', IndexNav);

new Vue({
  router,
  store,
  el: '#app',
  render: (h) => h(App),
}).$mount('#app');
