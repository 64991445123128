import request from '@/utils/request'
const baseUrl="/base";
export function getColumnList(data){
    return request({
        url:baseUrl+"/column/listAll",
        headers:{},
        method:"get",
        params:data
    })
}

export function getArticleList(data){
    return request({
        url:baseUrl+"/article/listByColumn",
        headers:{},
        method:"get",
        params:data
    })
}

export function getArticleListIndex(data){
    return request({
        url:baseUrl+"/article/listByIndex",
        headers:{},
        method:"get",
        params:data
    })
}

export function getArticle(articleId){
    return request({
        url:baseUrl+"/article/detail/"+articleId,
        method:"get"
    })
}