<template>
  <div>
    <!-- 头部组件 -->
    <IndexHead></IndexHead>
    <div class="Q" v-loading="this.loading.getArticle">
      <h1>{{ this.$store.state.article.article.title }}</h1>
      <i>发布时间：{{ this.$store.state.article.article.createTime }}</i>
      <hr>
      <div v-html="this.$store.state.article.article.content"></div>
      <hr>
    </div>
    <!-- 尾部组件 -->
    <IndexFooter></IndexFooter>
  </div>
</template>
<script>
import IndexHead from '@/components/IndexHead.vue'
import IndexFooter from '@/components/IndexFooter.vue'
export default {
  data(){
    return {
      loading:{
        getArticle:true
      }
    }
  },
  created(){
    this.loading.getArticle=true;
    this.$store.dispatch("article/getArticle",this.$route.params.articleCode).then(()=>{
      this.loading.getArticle=false;
    })
  },
  destroyed(){
    this.$store.dispatch("article/setArticle",{});
  },
  components: {
    IndexHead,
    IndexFooter,
  },
}
</script>
<style lang="scss">
@import '@/styles/ListDetailed.scss'
</style>