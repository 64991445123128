import request from '@/utils/request'
const baseUrl="/base";

export function getProductList(data){
    return request({
        url:baseUrl+"/product/listAll",
        headers:{},
        method:"get",
        params:data
    })
}