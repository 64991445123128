<template>
  <div>
    <div class="box">
      <!-- 版心 -->
      <div class="w">
        <!-- 头部 -->
        <el-row>
          <el-col :span="7">
            <div class="grid-content bg-purple-dark">
              <router-link to="/">
                <el-col :span="12">
                  <img src="@/assets/images/logo.png" alt="">
                </el-col>
              </router-link>
            </div>
          </el-col>
          <el-col :span="5">
            <div class="grid-content bg-purple-dark">
              <i class="el-icon-message">&nbsp;1078737959@QQ . COM</i>
            </div>
          </el-col>
          <el-col :span="5">
            <div class="grid-content bg-purple-dark">
              <!-- <i class="el-icon-phone">&nbsp;+86 13312345678</i> -->
            </div>
          </el-col>
          <el-col :span="2">
            <div class="grid-content bg-purple-dark">
              <span class="iconfont icon-weixin" @click="flag = !flag">
              </span>
            </div>
          </el-col>
          <el-col :span="5">
            <div class="grid-content bg-purple-dark">
              <template v-if="cookie!='' && userInfo.username!=null">
               <span style="color: orange;">{{ userInfo.username }} 您好!</span> 
            </template>
            <template v-if="cookie!=''">  
            <a href="/admin/article/order" target="_blank" > [ 控制台 ]</a>
          </template>
              <template v-if="cookie==''">
              <a href="/admin/login" target="_blank" > [ 登录/注册 ]</a>
            </template>
            </div>
          </el-col>

        </el-row>
        <img src="@/assets/images/weChat.png" class="erweima" v-show="flag">
      </div>
    </div>
  </div>
</template>
<script>
// import headDialog from '../components/HeadDialog.vue'
export default {
  data() {
    return {
      // v-show隐藏显示
      flag: false,

      userInfo:{},
      cookie:""

    }
  },

  methods: {
    
  },
  created(){
    
    let sessionObj = localStorage.getItem('sessionObj');
    if(sessionObj){
      sessionObj=JSON.parse(sessionObj)
    let data=JSON.parse(sessionObj.data)
    this.userInfo=data
    }
    this.cookie=IT.getCookie('Admin-Token')
  }
}
</script>
<style lang="scss" scoped>
.box .el-row img {
  width: auto;
  height: 50px;
}
</style>